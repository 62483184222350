import { LoginService } from "./../login/login.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { environment } from "../../environments/environment";

/**
Class used to extend the CanActivate method. It's used to run a piece of code
before a route to check if it can be activated or not
*/
@Injectable()
export class ClientGuard {
  constructor(
    public router: Router,
    public localStorage: LocalStorageService,
    public loginService: LoginService
  ) {}

  /**
   * Checks if user has access_token in localStorage.
   */
  canActivate() {
    if (
      this.loginService.get_access_token() !== undefined &&
      this.loginService.get_access_token() != null
    ) {
      if (this.localStorage.get("data")["user_type"] == 2) {
        return true;
      } else {
        return false;
      }
    }
  }
}
