import { LoginService } from "./../login/login.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { environment } from "../../environments/environment";
import { Intercom } from "@supy-io/ngx-intercom";
import { ConversationsService } from "../dashboard/conversations/conversations.service";
import { SharedDataService } from "../dashboard/shared-data.service";

/**
Class used to extend the CanActivate method. It's used to run a piece of code
before a route to check if it can be activated or not
*/
@Injectable()
export class AuthGuard {
  is_intercom_booted = false;

  constructor(
    public router: Router,
    public localStorage: LocalStorageService,
    public intercom: Intercom,
    public loginService: LoginService,
    public conversationsService: ConversationsService,
    public sharedDataService: SharedDataService,
  ) {}

  /**
   * Checks if user has access_token in localStorage.
   */
  async canActivate() {
    if (
      this.loginService.get_access_token() !== undefined &&
      this.loginService.get_access_token() != null
    ) {
      await this.loginService.loginToFirestore(false);
      if (!this.is_intercom_booted) {
        this.is_intercom_booted = true;

        if (
          this.sharedDataService.company != null &&
          this.sharedDataService.user != null &&
          this.sharedDataService.data != null
        ) {
          let intercomSettings = {
            app_id: "wqv1cgow",
            name: `${this.sharedDataService.user["first_name"]} ${
              this.sharedDataService.user["last_name"]
            }`,
            user_hash: this.sharedDataService.user["user_hash"],
            email: this.sharedDataService.user["email"],
            userid: this.sharedDataService.user["id"],
            created_at: new Date(
              this.sharedDataService.data["created"],
            ).getTime(),
            custom_data: {
              user_company: this.sharedDataService.company["is_accounting"],
              company_name: this.sharedDataService.company["name"],
              accounting_company_name:
                this.sharedDataService.company["accounting_company"] != null
                  ? this.sharedDataService.company["accounting_company"]["name"]
                  : null,
              user_type: this.sharedDataService.data["user_type"],
              user_system: environment.production ? "prod" : "qa",
              is_owner:
                this.sharedDataService.user["id"] ==
                this.sharedDataService.company["owner"]
                  ? true
                  : false,
              first_name: this.sharedDataService.user["first_name"],
              last_name: this.sharedDataService.user["last_name"],
            },
          };

          this.intercom.boot(intercomSettings);
        }
      }

      return true;
    }

    // not logged in so redirect to login page

    this.router.navigate(["/login"]);
    return false;
  }
}
